import React, { Component } from "react";
import "./App.css";
import Navigation from "./components/Navigation";
import HomePage from "./pages/HomePage";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";
import TestimonialsPage from "./pages/TestimonialsPage";
import ContactPage from "./pages/ContactPage";
import PictureWheel from "./components/PictureWheel";
class App extends Component {
  constructor() {
    super();
    this.state = {
      response: {},
      data: [],
    };
  }

  render() {
    return (
      <div className="App">
        <Row>
          <Col xs={1}></Col>
          <Col xs={10}>
            <Router>
              <Navigation />
              <PictureWheel />
              <br />
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/About" component={AboutPage} />
                <Route exact path="/Services" component={ServicesPage} />
                <Route
                  exact
                  path="/Testimonials"
                  component={TestimonialsPage}
                />
                <Route exact path="/Contact-Me" component={ContactPage} />
              </Switch>
            </Router>
            <br />
            <p> </p>
            <br />
            {/*FOOTER*/}
            <div
              style={{
                backgroundColor: "#695096",
                height: "70px",
                borderTop: "1px solid #ddd",
                lineHeight: "60px",
                textAlign: "center",
              }}
            >
              <p>© Sarah Harmer 2023. All Rights Reserved</p>
            </div>
          </Col>

          <Col xs={1}></Col>
        </Row>
      </div>
    );
  }
}

export default App;
