import React, { useState } from "react";
import Card from "react-bootstrap/Card";

function ServiceBox(props) {
  const subtitles = {
    fontFamily: "Raleway",
    color: "#1E392A",
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
  };

  const text = {
    color: "#1E392A",
    fontWeight: "",
    fontSize: "16px",
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Card
        style={{
          width: "95%",
          height: "100%",
          borderColor: "#695096",
          borderWidth: "3px",
          margin: "auto",
        }}
      >
        <Card.Body>
          <Card.Title style={subtitles}>{props.title}</Card.Title>
          <Card.Text style={text}>
            {props.content.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </Card.Text>
          <Card.Text style={(text, { fontWeight: "bold" })}>
            {props.price.split("\t").map((str) => (
              <p>{str}</p>
            ))}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ServiceBox;
