import React from "react";
import { Component } from "react";
import { Carousel, Col, Row } from "react-bootstrap";

import brightEyes from "../images/Carosell/Game9.jpeg";
import maneskin from "../images/Carosell/game2.jpeg";
import sour from "../images/Carosell/Cards5.jpeg";
import speakNow from "../images/Carosell/Game8.jpeg";
import folklore from "../images/Carosell/Cards6.jpeg";
import six from "../images/Carosell/Game5.jpeg";

class PictureWheel extends Component {
  constructor() {
    super();
    this.state = {
      carasol2Dis: "block",
      carasol3Dis: "block",
    };
    this.handleMedScreen = this.handleMedScreen.bind(this);
  }

  handleMedScreen() {
    this.setState({ carasol3Dis: "none" });
  }

  render() {
    const picArr1 = [brightEyes, maneskin, speakNow, folklore, six];
    const picArr2 = [maneskin, speakNow, folklore, six, brightEyes];
    const picArr3 = [speakNow, folklore, six, brightEyes, maneskin];
    const leftCarasel = [];
    const middleCarasel = [];
    const rightCarasel = [];

    for (let i = 0; i < picArr1.length; i++) {
      leftCarasel.push(
        <Carousel.Item interval={2000.5}>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <img className="d-block w-100" src={picArr1[i]} alt="First slide" />
          </div>
        </Carousel.Item>
      );
      middleCarasel.push(
        <Carousel.Item interval={2000}>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <img className="d-block w-100" src={picArr2[i]} alt="First slide" />
          </div>
        </Carousel.Item>
      );
      rightCarasel.push(
        <Carousel.Item interval={2000}>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <img
              className="d-block w-100"
              src={picArr3[i]}
              alt="First slide"
              width="100%"
            />
          </div>
        </Carousel.Item>
      );
    }

    return (
      <div class="container-fluid">
        <Row>
          <Col>
            <Carousel
              onClick={() => console.log("left")}
              indicators={false}
              controls={false}
              autoPlay={true}
              pause={false}
            >
              {leftCarasel}
            </Carousel>
          </Col>

          <Col>
            <Carousel
              indicators={false}
              controls={false}
              autoPlay={true}
              pause={false}
            >
              {middleCarasel}
            </Carousel>
          </Col>

          <Col>
            <Carousel
              onClick={() => console.log("right")}
              indicators={false}
              controls={false}
              autoPlay={true}
              pause={false}
            >
              {rightCarasel}
            </Carousel>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PictureWheel;
