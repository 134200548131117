import React, { Component } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import logo from "../images/LOGO.png";

import { Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    const navFont = {
      fontFamily: "Raleway",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#1C3444",
      textDecoration: "none",
      padding: "14px 16px",
    };

    const navWhole = {
      borderBottom: "5px solid #695096",
      borderTop: "5px solid #695096",
    };

    return (
      <>
        <Container>
          <Link to="/" className="navbar-logo">
            <Navbar.Brand to="/">
              {" "}
              <img
                alt=""
                src={logo}
                width="40%"
                height="100%"
                className="d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
          </Link>
          {/* #576874 */}
          <h4 style={{ color: "#1C3444" }}>
            Working with children to improve their communication skills
          </h4>
        </Container>
        <Navbar collapseOnSelect expand="md" style={navWhole}>
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav style={{ margin: "auto" }}>
                <Nav.Item>
                  <Link className="nav-links" style={navFont} to="/About">
                    About
                  </Link>{" "}
                </Nav.Item>
                <br />
                <Nav.Item>
                  <Link className="nav-links" style={navFont} to="/Services">
                    Services
                  </Link>{" "}
                </Nav.Item>
                <br />
                <Nav.Item>
                  <Link
                    className="nav-links"
                    style={navFont}
                    to="/Testimonials"
                  >
                    Testimonials
                  </Link>{" "}
                </Nav.Item>
                <br />
                <Nav.Item>
                  <Link className="nav-links" style={navFont} to="/Contact-Me">
                    Contact Me
                  </Link>{" "}
                </Nav.Item>
                <br />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
export default Navigation;
