import React, { useEffect, Component } from "react";
import TestomonialData from "../Testimonials.json";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styles from "../styles/speechbubble.css";

class TestomonialPage extends Component {
  render() {
    return (
      <>
        <h1 style={{ color: "#1C3444", lineWidth: "3000%" }}>Testimonials</h1>

        <br />
        <Row
          xs={1}
          sm={1}
          md={1}
          lg={2}
          xl={2}
          xxl={3}
          className="g-4 justify-content-center"
        >
          {Object.keys(TestomonialData).map((key, i) => (
            <Col>
              <div className="bubble" style={{ width: "90%", margin: "auto" }}>
                <p>{TestomonialData[key]}</p>
              </div>
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

export default TestomonialPage;
