import React, { useEffect, Component } from "react";
import Card from "react-bootstrap/Card";
import portrait from "../images/Mother1.jpg";
import credit1 from "../images/TherapistLOGO.png";
import credit2 from "../images/TherapistLOGO2.png";
import credit3 from "../images/TherapistLOGO3.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
class AboutPage extends Component {
  render() {
    const cardstyle = {
      width: "95%",
      height: "100%",
      fontSize: "18px",
      borderColor: "#000000",
      borderWidth: "3px",
      margin: "auto",
    };
    return (
      <>
        <br />
        <h1 style={{ color: "#1C3444", lineWidth: "3000%" }}>About</h1>
        <div class="AMsection">
          <p id="center" style={{ fontSize: "20px" }}>
            <strong>
              My name is Sarah Harmer and I am a Specialist Speech and Language
              Therapist (SLT) working in Thames Ditton, Surrey, and the
              surrounding area.
            </strong>
          </p>
          <Row
            xs={1}
            sm={1}
            md={1}
            lg={2}
            xl={2}
            xxl={3}
            className="g-4 justify-content-center"
          >
            <Col>
              <Card>
                <Card.Body style={{ textAlign: "left" }}>
                  <Card.Text style={cardstyle}>
                    I have worked as a SLT for over 27 years, working for the
                    NHS and in schools. <br /> I have worked as an Independent
                    SLT since 2014, seeing children at home, nursery or school.
                    Since April 2020, I have also provided effective therapy
                    remotely (i.e. teletherapy using Zoom) when face-to-face
                    sessions are not possible.
                    <br />I enjoy working with children of all ages from
                    pre-school to young adults. I am experienced in the
                    assessment, diagnosis and treatment of difficulties in the
                    following areas:
                    <ul>
                      <li>
                        Speech sounds. i.e. the production (articulation) and
                        use (phonology) of sounds.
                      </li>
                      <li>
                        Early language skills, like attention, listening and
                        play.
                      </li>
                      <li>Understanding and use of spoken language.</li>
                      <li>
                        Social communication skills, like turn taking, emotional
                        expression and developing understanding and use of
                        'social thinking'.
                      </li>
                      <li>
                        Developing practical, functional communication skills.
                      </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <div style={{ textAlign: "center" }}>
                <img id="profilePic" src={portrait} width="70%"></img>
              </div>
            </Col>
            <Col>
              <Card>
                <Card.Body style={cardstyle}>
                  <Card.Text style={{ textAlign: "left" }}>
                    <p>
                      I have a specific interest in working with children with
                      more complex communication needs, who benefit from using
                      alternative or supplementary communication (AAC), such as
                      Makaton signs and symbols and the Picture Exchange
                      Communication System (PECS).
                    </p>
                    <p>
                      I qualified as a Speech and Language Therapist in 1986
                      having obtained a Postgraduate Diploma in Clinical
                      Communication Studies, from the City University London.
                      Prior to this, I obtained a BSc Honours Degree in Biology,
                      from Imperial College, London.
                    </p>
                  </Card.Text>
                  <Card.Text>I am a member of:</Card.Text>
                  <div class="LogoContainer">
                    <a href="https://www.rcslt.org/">
                      <img class="logoImageSec" src={credit1} />
                    </a>
                    <a href="https://www.hcpc-uk.org/">
                      <img class="logoImageSec" src={credit2} />
                    </a>
                    <a href="https://asltip.com/">
                      <img class="logoImageSec" src={credit3} />
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AboutPage;
